const firebaseConfig = {
  apiKey: "AIzaSyAngcDaDQ7PZi7oQ6GXGyo2YmO3A-9jFLs",
  authDomain: "workhealthy-5f2cd.firebaseapp.com",
  databaseURL: "https://workhealthy-5f2cd.firebaseio.com",
  projectId: "workhealthy-5f2cd",
  storageBucket: "workhealthy-5f2cd.appspot.com",
  messagingSenderId: "1092773883801",
  appId: "1:1092773883801:web:4d44b2db526e824b732d08",
  measurementId: "G-91M6H4Y9Z0"
};

export default firebaseConfig;
